export const environment = {
  production: true,
  federationServiceUrl: 'https://federation-qa.basf.com/nidp/',
  federationService: true,
  backendUrl: 'https://dev.materialpassport.basf.com/',
  edcProtocolUrl: 'https://basf-c-x-dev-001.prod-sovity.azure.sovity.io/control/api/v1/dsp',
  clientId: '4af3b8d0-1b10-492b-b28a-2d433fdd87b3',
  redirectUri: 'https://dev.materialpassport.basf.com',
  scope: 'openid CFDS',
  resourceServer: 'CFDS_DEV',
  responseType: 'code',
  appName: 'BASF Material Passport',
  shortName: 'Material Passport',
  mailTo: '',
  version: '1.0.0',
  envName: 'DEV'
};
